#about {
  padding: 0 20px;
  max-width: 1150px;
  margin: auto;
}

#about p {
  font-size: 20px;
  line-height: 1.5;
}
