header {
  width: 100%;
  background-color: #e79652;
}

header .center-column {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

header > div {
  padding: 0 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
}

header h1 {
  margin: 0;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 38px;
}

header nav {
  height: 60px;
  padding: 0 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f1d736;
  font-size: 20px;
}

header nav ol {
  padding: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

@media (min-width: 600px) {
  header nav ol {
    display: block;
  }
  header nav li {
    margin-right: 30px;
  }
}

header nav li {
  display: inline;
}

header nav a {
  color: inherit;
}

#about {
  padding: 0 20px;
  max-width: 1150px;
  margin: auto;
}

#about p {
  font-size: 20px;
  line-height: 1.5;
}

div#root {
  height: 100%;
}

